const actions = {
  SET_STATE: 'bundle/SET_STATE',
  GET_PAGINATE: 'bundle/GET_PAGINATE',
  GET_ORDER: 'bundle/GET_ORDER',
  GET_MY_FUCKING_BUNDLE: 'bundle/GET_MY_FUCKING_BUNDLE',
  GET_BY_ID: 'bundle/GET_BY_ID',
  GET_PACKET_ANSWER_USER_COMPLETE: 'bundle/GET_PACKET_ANSWER_USER_COMPLETE',
  GET_CATEGORY: 'bundle/GET_CATEGORY',
  POST_BUY: 'bundle/POST_BUY',
  POST_UNLOCK: 'bundle/POST_UNLOCK',
  RESET_DEFAULT: 'bundle/RESET_DEFAULT',
}

export default actions
