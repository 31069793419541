import actions from './actions'

export const initialState = {
  listFuckingBundle: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 9,
      pageCount: 0,
      totalCount: 0,
      search: '',
      packetId: 'all',
      bundleId: 'all',
      sort: 'packet_name',
      bundleCategory: 'semua',
    },
  },
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      status: 'all',
      date: 'all',
      bundleId: 'all',
      sort: 'created_at',
    },
  },
  listAll: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'bundle_name',
      bundleCategory: 'all',
    },
  },
  id: '',
  tabs: 'TryoutTersediaPane',
  bundleCategory: [],
  loading: false,
  packetAnswerUserComplete: [],
}

export default function bundleReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
