import apiClient from 'services/axios'
import response from 'services/response'

export async function pointsPaginate(payload) {
  const { perPage, currentPage, sort, search } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(`finance/point/${perPage}/${currentPage}/${sort}${params}`)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function pointsBalance() {
  let res = false
  try {
    res = await apiClient.get(`finance/point/balance`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function pointsAmount(amount) {
  let res = false
  try {
    res = await apiClient.post(`finance/point/${amount}`)
    return res.data.data
  } catch (error) {
    return false
  }
}
