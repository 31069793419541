import apiClient from 'services/axios'
import response from 'services/response'

export async function campusPaginate(payload) {
  const { perPage, currentPage, sort, search } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(`master/campus/${perPage}/${currentPage}/${sort}${params}`)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function campusListAll() {
  let res = null
  try {
    res = await apiClient.get(`master/campus/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function campusCreate(payload) {
  let res = null
  try {
    res = apiClient.post(`master/campus`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function campusUpdate(id, data) {
  let res = null
  try {
    res = apiClient.post(`master/campus/${id}`, data)
    return res.data
  } catch (error) {
    return false
  }
}

export async function campusFindById(id) {
  let res = null
  try {
    res = apiClient.get(`master/campus/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function campusDelete(id) {
  let res = null
  try {
    res = apiClient.delete(`master/campus/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}
