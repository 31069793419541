import actions from './actions'

function model(p, q) {
  return {
    listTable: {
      data: [],
      paginate: {
        currentPage: 1,
        perPage: 10,
        pageCount: 0,
        totalCount: 0,
        search: '',
        sort: p,
      },
    },
    data: [],
    loading: false,
    ...(q || {}),
  }
}

const initialState = {
  banner: {
    ...model('created_at'),
  },
  video: {
    ...model('created_at'),
  },
  faqCategory: {
    ...model('created_at'),
  },
  faq: {
    ...model('cms_faq_order', { categoryId: 'all' }),
  },
  ebookCategory: {
    ...model('created_at'),
  },
  ebook: {
    ...model('cms_ebook_name', { categoryId: 'all', search: '' }),
  },
  loading: false,
}

export default function cmsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
