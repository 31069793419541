import apiClient from 'services/axios'
import response from 'services/response'

export async function cmsPaginate(payload) {
  const { perPage, currentPage, sort, search, suffix } = payload
  let res = null
  const params = search ? `/${search}` : ''
  const q = payload?.categoryId ? `/${payload.categoryId}` : ''
  try {
    res = await apiClient.get(`master/cms-${suffix}/${perPage}/${currentPage}/${sort}${q}${params}`)
    console.log(res)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function cmsListAll(payload) {
  let res = null
  try {
    if (typeof payload === 'string') {
      res = await apiClient.get(`master/cms-${payload}/list-all`)
    } else {
      const q = payload?.search ? `/${payload.search}` : ''
      console.log(q)
      res = await apiClient.get(`master/cms-${payload.suffix}/list-all/${payload.filter}${q}`)
    }
    return res.data.data
  } catch (error) {
    return false
  }
}
