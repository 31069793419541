import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  bundlePaginate,
  bundleBuy,
  bundleUnlock,
  bundleOrder,
  bundleGetCategory,
  bundleFuckingBundle,
  packetAnswerUserComplete,
} from 'services/bundle'
import { initialState } from './reducers'
import actions from './actions'

export function* GET_MY_FUCKING_BUNDLE() {
  const { listFuckingBundle } = yield select(state => state.bundle)
  yield loading()
  const success = yield call(bundleFuckingBundle, { ...listFuckingBundle.paginate, perPage: 9 })
  if (success) {
    yield put({
      type: 'bundle/SET_STATE',
      payload: {
        listFuckingBundle: {
          data: success.data,
          paginate: {
            ...listFuckingBundle.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_PAGINATE() {
  const { listAll } = yield select(state => state.bundle)
  yield loading()
  const success = yield call(bundlePaginate, listAll.paginate)
  if (success) {
    yield put({
      type: 'bundle/SET_STATE',
      payload: {
        listAll: {
          data: success.data,
          paginate: {
            ...listAll.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_ORDER() {
  const { listTable } = yield select(state => state.bundle)
  yield loading()
  const success = yield call(bundleOrder, listTable.paginate)
  if (success) {
    yield put({
      type: 'bundle/SET_STATE',
      payload: {
        listTable: {
          data: success.data,
          paginate: {
            ...listTable.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_CATEGORY() {
  yield loading()
  const success = yield call(bundleGetCategory)
  if (success) {
    yield put({
      type: 'bundle/SET_STATE',
      payload: {
        bundleCategory: success,
      },
    })
  }
  yield loaded()
}

export function* POST_BUY({ payload }) {
  yield loading()
  const success = yield call(bundleBuy, payload)
  if (success) {
    yield put({
      type: 'bundle/GET_PAGINATE',
    })
  }
  yield loaded()
}

export function* POST_UNLOCK({ payload }) {
  yield loading()
  const success = yield call(bundleUnlock, payload)
  if (success) {
    yield put({
      type: 'bundle/GET_PAGINATE',
    })
  }
  yield loaded()
}

export function* GET_PACKET_ANSWER_USER_COMPLETE() {
  yield loading()
  const success = yield call(packetAnswerUserComplete)
  if (success) {
    yield put({
      type: 'bundle/SET_STATE',
      payload: {
        packetAnswerUserComplete: success.data,
      },
    })
  }
  yield loaded()
}

export function* RESET_DEFAULT() {
  yield put({
    type: 'bundle/SET_STATE',
    payload: {
      ...initialState,
    },
  })
}

export function* loading() {
  yield put({
    type: 'bundle/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'bundle/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.GET_ORDER, GET_ORDER),
    takeEvery(actions.GET_CATEGORY, GET_CATEGORY),
    takeEvery(actions.POST_BUY, POST_BUY),
    takeEvery(actions.POST_UNLOCK, POST_UNLOCK),
    takeEvery(actions.GET_MY_FUCKING_BUNDLE, GET_MY_FUCKING_BUNDLE),
    takeEvery(actions.GET_PACKET_ANSWER_USER_COMPLETE, GET_PACKET_ANSWER_USER_COMPLETE),
    takeEvery(actions.RESET_DEFAULT, RESET_DEFAULT),
  ])
}
