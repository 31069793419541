import apiClient from 'services/axios'

export async function reportsSummary() {
  let res = false
  try {
    res = await apiClient.get(`tryout/learning-report/summary`)
    return res.data
  } catch (error) {
    console.log(error)
    return false
  }
}

export async function reportsMaterial(payload) {
  let res = false
  const { packetId, categoryId } = payload
  try {
    if (categoryId) {
      res = await apiClient.get(
        `tryout/bundle-order/list-by-category/all/all/1000/1/packet_name/${categoryId}`,
      )
    } else {
      res = await apiClient.get(`tryout/learning-report/material-report/${packetId}`)
    }
    return res.data
  } catch (error) {
    console.log(error)
    return false
  }
}

export async function reportsSubMaterial(payload) {
  const { packetId, materialId } = payload
  let res = false
  try {
    res = await apiClient.get(`tryout/learning-report/submaterial-report/${packetId}/${materialId}`)
    return res.data
  } catch (error) {
    console.log(error)
    return false
  }
}

export async function reportsCategory() {
  try {
    const {
      data: { data },
    } = await apiClient.get(`tryout/bundle-order/list-category-by-bundle-order`)
    return data
  } catch (error) {
    return false
  }
}
