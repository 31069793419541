import apiClient from 'services/axios'
import response from 'services/response'

export async function referralPaginate(payload) {
  const { perPage, currentPage, sort, search } = payload
  const params = search ? `/${search}` : ''
  let res = null
  try {
    res = await apiClient.get(`finance/afiliate-history/${perPage}/${currentPage}/${sort}${params}`)
    console.log(res)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function referralPaginateUser(payload) {
  const { perPage, currentPage, sort, search, date } = payload
  const params = search ? `/${search}` : ''
  try {
    const res = await apiClient.get(
      `finance/afiliate-user/list-by-user/${perPage}/${currentPage}/${sort}/${date ||
        'all'}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function referralPaginateTopUp(payload) {
  const { perPage, currentPage, sort, search, date } = payload
  const params = search ? `/${search}` : ''
  try {
    const res = await apiClient.get(
      `finance/afiliate-history-topup/${perPage}/${currentPage}/${sort}/${date || 'all'}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function referralPaginateHistoryDraw(payload) {
  const { perPage, currentPage, sort, search, date, status } = payload
  const params = search ? `/${search}` : ''
  try {
    const res = await apiClient.get(
      `finance/afiliate-history-draw/${perPage}/${currentPage}/${sort}/${date ||
        'all'}/${status}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function referralSummary() {
  try {
    const res = await apiClient.get(`finance/dashboard-afiliate/summary-enduser`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function referralSetting() {
  try {
    const res = await apiClient.get(`finance/afiliate-bank-payment/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function referralListBank() {
  try {
    const res = await apiClient.get(`master/bank/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function referralBalance() {
  try {
    const res = await apiClient.get(`finance/afiliate-history/balance`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function referralBonusAmount() {
  try {
    const res = await apiClient.get(`master/setting/view-by-key/Saldo Bonus`)
    return res.data.value
  } catch (error) {
    return false
  }
}

export async function referralBonusPercent() {
  try {
    const res = await apiClient.get(`master/setting/view-by-key/Persen Referal`)
    return res.data.value
  } catch (error) {
    return false
  }
}
