import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { CalendarIcon, MoneyBagIcon } from 'assets/icons'
import { dateTimeFormat, toThousand } from 'helper'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const mapStateToProps = ({ user, topup }) => ({
  user,
  amount: topup.balance.koin_balance_amount,
})
const TopBar = ({ user, amount }) => {
  const history = useLocation()
  return (
    <div className={style.topbar}>
      {history.pathname.split('/')[1] === 'excercise' ||
      history.pathname.split('/')[1] === 'pembahasan' ? (
        <img src="/logo.svg" height={30} alt="logo" />
      ) : (
        <div className="">
          <span
            className="align-middle p-2"
            style={{
              background: 'rgba(71, 188, 188, 0.07)',
              backgroundImage: 'url(/resources/images/wave/buttonBlue.svg)',
              backgroundSize: 'cover',
              borderRadius: '6px',
            }}
          >
            <MoneyBagIcon className="align-middle mr-2" />
            <span style={{ color: '#47BCBC' }} className="align-middle">
              {toThousand(amount || 0)}
            </span>
            <button
              type="button"
              className="btn btn-sm align-middle ml-2"
              style={{ background: '#47BCBC', borderRadius: '4px' }}
            >
              <Link to="/top-up" className="text-white">
                Top Up
              </Link>
            </button>
          </span>
        </div>
      )}
      <div className="ml-4">
        <span
          style={{
            backgroundColor: '#FFFAF2',
            backgroundImage: 'url(/resources/images/wave/buttonOrange.svg)',
            backgroundSize: 'cover',
            borderRadius: '6px',
            color: '#FE9900',
          }}
          className="align-middle p-2"
        >
          <CalendarIcon className="align-middle mr-2" />
          {dateTimeFormat({}, 'dddd, D MMMM YYYY')}
        </span>
      </div>
      <div className="ml-auto mr-3">
        <p className="m-0 text-right" style={{ whiteSpace: 'pre-wrap', lineHeight: 'normal' }}>
          <b>{user.nama_lengkap || user.username}</b>
          {'\n'}
          {!user.is_aktif && 'Tidak '}Terverifikasi
        </p>
      </div>
      <div>
        <UserMenu />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(TopBar)
