import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      status: 'semua',
      sort: '-created_at',
      date: 'all',
    },
  },
  balance: {
    koin_balance_amount: 0,
    koin_balance_used: 0,
  },
  summary: {
    menunggu: 0,
    berhasil: 0,
    dibatalkan: 0,
    kadaluarsa: 0,
  },
  is_ever_been_topup: false,
  listAll: [],
  id: '',
  data: '',
  forms: {
    item: [
      {
        name: 'amount',
        rules: [
          { required: true, message: 'Nominal Top Up Tidak Boleh Kosong' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || +getFieldValue('amount') >= 25000) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Nominal Top Up Minimal adalah Rp25.000'))
            },
          }),
        ],
        className: 'col-9 pb-4',
        children: {
          size: 'large',
          component: 'input',
          placeholder: 'Ketik Nominal Top Up',
          addonBefore: 'Rp',
        },
      },
    ],
    fields: [{ name: ['amount'] }],
  },
  loading: false,
}

export default function topupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
