import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import category from './category/sagas'
import campus from './campus/sagas'
import packet from './packet/sagas'
import excercise from './excercise/sagas'
import answer from './answer/sagas'
import coupon from './coupon/sagas'
import voucher from './voucher/sagas'
import topup from './topup/sagas'
import bundle from './bundle/sagas'
import cms from './cms/sagas'
import users from './users/sagas'
import points from './points/sagas'
import referral from './referral/sagas'
import dashboard from './dashboard/sagas'
import reports from './reports/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    category(),
    campus(),
    packet(),
    excercise(),
    answer(),
    coupon(),
    voucher(),
    topup(),
    bundle(),
    cms(),
    users(),
    points(),
    referral(),
    dashboard(),
    reports(),
  ])
}
