const actions = {
  SET_STATE: 'excercise/SET_STATE',
  GET_QUESTION: 'excercise/GET_QUESTION',
  GET_ANSWER: 'excercise/GET_ANSWER',
  GET_CORRECTION: 'excercise/GET_CORRECTION',
  GET_PEMBAHASAN: 'excercise/GET_PEMBAHASAN',
  GET_TIMER: 'excercise/GET_TIMER',
}

export default actions
