import axios from 'axios'
import apiClient from 'services/axios'
import response from 'services/response'
import store from 'store'

export async function bundleFuckingBundle(payload) {
  const { perPage, currentPage, sort, search, bundleId, packetId } = payload
  let res = false
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(
      `tryout/bundle-order/${bundleId}/${packetId}/${perPage}/${currentPage}/${sort}${params}`,
    )
    console.log(res)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function packetAnswerUserComplete() {
  try {
    const { data } = await apiClient.get(
      `tryout/bundle-order/list-page-packet-answer-user-complete`,
    )
    return data
  } catch (error) {
    return false
  }
}

export async function bundlePaginate(payload) {
  const { perPage, currentPage, sort, search, bundleCategory } = payload
  let res = false
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(
      `tryout/bundle/${bundleCategory}/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function bundleOrder(payload) {
  const { perPage, currentPage, sort, search, status, date, bundleId } = payload
  const params = search ? `/${search}` : ''
  try {
    const { data } = await apiClient.get(
      `finance/bundle-order/list-purchase/${status}/${perPage}/${currentPage}/${sort}/${date ||
        'all'}/${bundleId}${params}`,
    )
    return response({ ...data, search, sort, status, date })
  } catch (error) {
    return false
  }
}

export async function bundleBuy(payload) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_HOST}finance/bundle-order/buy`,
      payload,
      {
        headers: {
          accept: 'application/json',
          app_name: 'siswa',
          Authorization: `Bearer ${store.get('accessToken')}`,
        },
      },
    )
    return data
  } catch (e) {
    console.log(e.response)
    return e.response
  }
}

export async function bundleGetCategory() {
  let res = false
  try {
    res = await apiClient.get(`tryout/bundle-category`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function bundleById(payload) {
  let res = false
  try {
    res = await apiClient.post(`tryout/bundle/${payload}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function bundleUnlock(payload) {
  let res = false
  try {
    res = await apiClient.post(`finance/bundle-order/unlock`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function bundleUploadTerm(payload) {
  let res = false
  try {
    res = await apiClient.post(`tryout/bundle-custom-term-user-upload/upload`, payload)
    return res.data
  } catch (error) {
    return false
  }
}
