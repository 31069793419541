import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { Spin } from 'antd'

import Layout from 'layouts'

const routes = [
  {
    path: '/r/:code',
    Component: lazy(() => import('pages/auth')),
    exact: true,
  },
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/password-verified/verify/:email/:token',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/register/:referral',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/verify/:email/:token',
    Component: lazy(() => import('pages/auth/verify')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/profile',
    Component: lazy(() => import('pages/profile')),
    exact: true,
  },
  // morid routes
  {
    path: '/beranda',
    Component: lazy(() => import('pages/beranda')),
    exact: true,
  },
  {
    path: '/voucher',
    Component: lazy(() => import('pages/voucher')),
    exact: true,
  },
  {
    path: '/top-up',
    Component: lazy(() => import('pages/top-up')),
    exact: true,
  },
  {
    path: '/try-out',
    Component: lazy(() => import('pages/try-out')),
    exact: true,
  },
  {
    path: '/excercise/:packetAnswerUser/:bundleOrderId/:packetId/:index',
    Component: lazy(() => import('pages/excercise')),
    exact: true,
  },
  {
    path: '/payment/finish/:id/:status',
    Component: lazy(() => import('pages/payment/finish')),
    exact: true,
  },
  {
    path: '/payment/unfinish/:id/:status',
    Component: lazy(() => import('pages/payment/unfinish')),
    exact: true,
  },
  {
    path: '/payment/error/:id/:status',
    Component: lazy(() => import('pages/payment/error')),
    exact: true,
  },
  {
    path: '/review/:bundleOrderId/:packetId',
    Component: lazy(() => import('pages/excercise/review')),
    exact: true,
  },
  {
    path: '/pembahasan/:bundleOrderId/:packetId',
    Component: lazy(() => import('pages/excercise/review/detail')),
    exact: true,
  },
  {
    path: '/raporbelajar',
    Component: lazy(() => import('pages/reports')),
    exact: true,
  },
  {
    path: '/ebook',
    Component: lazy(() => import('pages/ebook')),
    exact: true,
  },
  {
    path: '/points',
    Component: lazy(() => import('pages/points')),
    exact: true,
  },
  {
    path: '/referral',
    Component: lazy(() => import('pages/referral')),
    exact: true,
  },
  {
    path: '/promo',
    Component: lazy(() => import('pages/kupon')),
    exact: true,
  },
  {
    path: '/promo/:id',
    Component: lazy(() => import('pages/kupon/detail')),
    exact: true,
  },
  {
    path: '/faq',
    Component: lazy(() => import('pages/faq')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={`h-100 ${routerAnimation}`}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/beranda" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense
                                fallback={
                                  <div
                                    style={{ display: 'grid', placeItems: 'center' }}
                                    className="h-100"
                                  >
                                    <Spin size="large" />
                                  </div>
                                }
                              >
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
