import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import { pointsPaginate, pointsAmount, pointsBalance } from 'services/points'
import actions from './actions'

export function* GET_PAGINATE() {
  const { listTable } = yield select(state => state.points)
  yield loading()
  const success = yield call(pointsPaginate, listTable.paginate)
  if (success) {
    yield put({
      type: 'points/SET_STATE',
      payload: {
        listTable: success,
      },
    })
  }
  yield loaded()
}

export function* POST_AMOUNT({ payload }) {
  const { amount } = payload
  yield loading()
  const success = yield call(pointsAmount, amount)
  if (success) {
    console.log(success)
  }
  yield loaded()
}

export function* GET_BALANCE() {
  yield loading()
  const success = yield call(pointsBalance)
  if (success) {
    yield put({
      type: 'points/SET_STATE',
      payload: success,
    })
  }
  yield loaded()
}

export function* loading() {
  yield put({
    type: 'points/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'points/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.POST_AMOUNT, POST_AMOUNT),
    takeEvery(actions.GET_BALANCE, GET_BALANCE),
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
  ])
}
