import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 1000,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'packet_name',
    },
  },
  listAll: [],
  id: '',
  data: {},
  forms: {},
  loading: false,
  visible: false,
}

export default function packetReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
