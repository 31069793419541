import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: '-afiliate_register_at',
    },
  },
  listPaginateUser: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: '-created_at',
      date: 'all',
    },
  },
  listPaginateTopUp: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: '-created_at',
      date: 'all',
    },
  },
  listPaginateHistoryDraw: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: '-created_at',
      date: 'all',
      status: 'all',
    },
  },
  listAll: [],
  listBank: [],
  id: '',
  loading: false,
  summary: {
    activeComission: 0,
    userReferalCount: 0,
    userTopupCount: 0,
  },
  tab: 'ReferralTab',
  balance: {},
  bonusAmount: 0,
  bonuspercent: 0,
}

export default function referralReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
