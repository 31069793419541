import actions from './actions'

const initialState = {
  id: '',
  username: '',
  nama_lengkap: '',
  email: '',
  school_from: '',
  year_graduation: '',
  birthday_place: '',
  birthday_date: '',
  phone_number: '',
  instagram_username: '',
  avatar_url: '',
  afiliate_code: '',
  afiliate_code_referal: '',
  district: { id: '' },
  province: { id: '' },
  role: '',
  is_aktif: false,
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  loading: false,
  is_profile_empty: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
