/* eslint-disable camelcase */
import apiClient from 'services/axios'
import store from 'store'

export async function login(username, password) {
  try {
    const { data } = await apiClient.post('/auth/sign-in', {
      username,
      password,
      user_type: `${process.env.REACT_APP_USER_TYPE}`,
    })
    const { token } = data
    if (token) {
      store.set('accessToken', token)
      store.set('user', JSON.stringify(data))
    } else {
      return false
    }
    return true
  } catch (e) {
    return false
  }
}

export async function register(payload) {
  let res = false
  try {
    res = await apiClient.post('/auth/create-student-user', {
      ...payload,
      role: { role_display_name: 'siswa' },
    })
  } catch (error) {
    console.log(error)
  }
  return res
}

export async function currentAccount() {
  let res = false
  if (!store.get('accessToken')) return res
  try {
    res = await apiClient.get('/auth/view')
    return res.data
  } catch (error) {
    console.log(error)
    return false
  }
}

export async function logout() {
  return store.clearAll()
}

export async function passwordEmail({ payload }) {
  let res = null
  try {
    res = await apiClient.post('auth/update-password', payload)
    return res
  } catch (error) {
    console.log(error)
    return false
  }
}

export async function profile({ payload }) {
  let res = null
  try {
    res = await apiClient.post('auth/update', payload)
    return res.data.staff
  } catch (error) {
    console.log(error)
    return false
  }
}

export async function avatar({ payload }) {
  let res = false
  try {
    res = await apiClient.post('auth/update-avatar-url', payload)
    return res
  } catch (error) {
    console.log(error)
    return res
  }
}
