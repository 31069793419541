import { history } from 'index'
import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  excerciseQuestion,
  excerciseCorrection,
  excerciseAnswer,
  excerciseTimer,
} from 'services/excercise'
import actions from './actions'

export function* GET_PEMBAHASAN({ payload }) {
  yield loading()
  const success = yield call(excerciseQuestion, payload)
  if (success) {
    // const { id } = yield select(state => state.excercise)
    yield put({
      type: 'excercise/SET_STATE',
      payload: {
        question: success,
        id: success[0].packetAnswerUserDetailList[0].excercise.id,
      },
    })
  }
  yield loaded()
}

export function* GET_TIMER({ payload }) {
  const success = yield call(excerciseTimer, payload)
  if (success) {
    yield put({
      type: 'excercise/SET_STATE',
      payload: {
        timer: success.created_at,
      },
    })
  }
  yield loaded()
}

export function* GET_QUESTION({ payload }) {
  yield loading()
  const success = yield call(excerciseQuestion, payload)
  if (success) {
    // const { id } = yield select(state => state.excercise)
    const { index } = payload
    yield put({
      type: 'excercise/SET_STATE',
      payload: {
        question: success,
        id: success[index].packetAnswerUserDetailList[0].excercise.id,
      },
    })
    if (history.location?.state?.from) {
      yield put({
        type: 'excercise/GET_TIMER',
        payload: {
          id: success[index].id,
        },
      })
    } else {
      yield loaded()
    }
  }
}

export function* GET_ANSWER() {
  yield loading()
  const { id } = yield select(state => state.excercise)
  const success = yield call(excerciseAnswer, id)
  if (success) {
    yield put({
      type: 'excercise/SET_STATE',
      payload: {
        answer: success,
      },
    })
  }
  yield loaded()
}

export function* GET_CORRECTION({ payload }) {
  yield loading()
  const success = yield call(excerciseCorrection, payload)
  if (success) {
    yield put({
      type: 'excercise/SET_STATE',
      payload: {
        correction: success,
      },
    })
  }
  yield loaded()
}

export function* loading() {
  yield put({
    type: 'excercise/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'excercise/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_QUESTION, GET_QUESTION),
    takeEvery(actions.GET_CORRECTION, GET_CORRECTION),
    takeEvery(actions.GET_ANSWER, GET_ANSWER),
    takeEvery(actions.GET_PEMBAHASAN, GET_PEMBAHASAN),
    takeEvery(actions.GET_TIMER, GET_TIMER),
  ])
}
