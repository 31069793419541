import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import category from './category/reducers'
import campus from './campus/reducers'
import packet from './packet/reducers'
import excercise from './excercise/reducers'
import answer from './answer/reducers'
import coupon from './coupon/reducers'
import voucher from './voucher/reducers'
import topup from './topup/reducers'
import bundle from './bundle/reducers'
import cms from './cms/reducers'
import users from './users/reducers'
import points from './points/reducers'
import referral from './referral/reducers'
import dashboard from './dashboard/reducers'
import reports from './reports/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    category,
    campus,
    packet,
    excercise,
    answer,
    coupon,
    voucher,
    topup,
    bundle,
    cms,
    users,
    points,
    referral,
    dashboard,
    reports,
  })
