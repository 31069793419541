import apiClient from 'services/axios'
import response from 'services/response'

export async function answerPaginate(payload) {
  const { perPage, currentPage, sort, search, excerciseId } = payload
  let res = null
  try {
    res = await apiClient.get(`master/answer/${excerciseId}/${perPage}/${currentPage}/${sort}`)
    console.log(res)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function answerStore(payload) {
  let res = null
  const suffix = payload.id ? `/${payload.id}` : ``
  try {
    res = await apiClient.post(`master/answer${suffix}`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function answerDelete(id) {
  let res = null
  try {
    res = await apiClient.delete(`master/answer/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}
