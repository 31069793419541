const actions = {
  SET_STATE: 'referral/SET_STATE',
  GET_PAGINATE: 'referral/GET_PAGINATE',
  GET_PAGINATE_USER: 'referral/GET_PAGINATE_USER',
  GET_PAGINATE_TOP_UP: 'referral/GET_PAGINATE_TOP_UP',
  GET_PAGINATE_HISTORY_DRAW: 'referral/GET_PAGINATE_HISTORY_DRAW',
  GET_SUMMARY: 'referral/GET_SUMMARY',
  GET_SETTING: 'referral/GET_SETTING',
  GET_BANK: 'referral/GET_BANK',
  GET_BALANCE: 'referral/GET_BALANCE',
  GET_BONUS_AMOUNT: 'referral/GET_BONUS_AMOUNT',
  GET_BONUS_PERCENT: 'referral/GET_BONUS_PERCENT',
}

export default actions
