import React from 'react'
import {
  BerandaIcon,
  MasterTryOutIcon,
  KuponIcon,
  VoucherIcon,
  TopUpIcon,
  FaqIcon,
  // PointsIcon,
  EbookIcon,
  RaporIcon,
  AffiliateIcon,
} from 'assets/icons'

export default async function getMenuData() {
  return [
    {
      title: 'Beranda',
      key: 'beranda',
      icon: <BerandaIcon className="align-middle text-center" />,
      url: '/beranda',
    },
    {
      title: 'Koin & Top Up',
      key: 'top-up',
      icon: <TopUpIcon className="align-middle text-center" />,
      url: '/top-up',
    },
    {
      title: 'Voucher',
      key: 'voucher',
      icon: <VoucherIcon className="align-middle text-center" />,
      url: '/voucher',
    },
    {
      title: 'Try Out',
      key: 'try-out',
      icon: <MasterTryOutIcon className="align-middle text-center" />,
      url: '/try-out',
    },
    {
      title: 'Rapor Belajar',
      key: 'raporbelajar',
      icon: <RaporIcon className="align-middle text-center" />,
      url: '/raporbelajar',
    },
    {
      title: 'E-Book',
      key: 'ebook',
      icon: <EbookIcon className="align-middle text-center" />,
      url: '/ebook',
    },
    // {
    //   title: 'My Poin',
    //   key: 'points',
    //   icon: <PointsIcon className="align-middle text-center" />,
    //   url: '/points',
    // },
    {
      title: 'Referral',
      key: 'referral',
      icon: <AffiliateIcon className="align-middle text-center" />,
      url: '/referral',
    },
    {
      title: 'Promo',
      key: 'kupon',
      icon: <KuponIcon className="align-middle text-center" />,
      url: '/promo',
    },
    {
      title: 'FAQ',
      key: 'faq',
      icon: <FaqIcon className="align-middle text-center" />,
      url: '/faq',
    },
  ]
}
