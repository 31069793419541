export default function response(payload) {
  const { data, _meta, search, sort } = payload
  return {
    data,
    paginate: {
      sort,
      search,
      ...{ ..._meta },
    },
  }
}
