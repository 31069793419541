import apiClient from 'services/axios'

export async function excerciseQuestion(payload) {
  const { bundleOrderId, packetId } = payload
  let res = false
  try {
    res = await apiClient.get(`tryout/packet-answer-user/${bundleOrderId}/${packetId}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function excerciseAnswer(payload) {
  let res = false
  try {
    res = await apiClient(`master/answer/${payload}/100/1/answer_name`)
    return res.data.data
  } catch (error) {
    return console.log(error)
  }
}

export async function excerciseCorrection(payload) {
  const { bundleOrderId, packetId } = payload
  let res = null
  try {
    res = await apiClient.get(`tryout/packet-answer-user/correction/${bundleOrderId}/${packetId}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function excerciseStore(payload) {
  let res = null
  const suffix = payload.id ? `/${payload.id}` : ``
  try {
    res = await apiClient.post(`master/excercise${suffix}`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function excerciseTimer(payload) {
  try {
    const res = await apiClient.post(`tryout/packet-answer-user/update-date`, { ...payload })
    return res.data
  } catch (error) {
    return error
  }
}
