import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  topupPaginate,
  topupListAll,
  topupGetBalance,
  topupGetSummary,
  topupGetIsTopUp,
} from 'services/topup'
import actions from './actions'

export function* GET_PAGINATE() {
  const { listTable } = yield select(state => state.topup)
  yield loading()
  const success = yield call(topupPaginate, listTable.paginate)
  if (success) {
    yield put({
      type: 'topup/SET_STATE',
      payload: {
        listTable: {
          data: success.data,
          paginate: {
            ...listTable.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
  yield put({
    type: 'topup/GET_BALANCE',
  })
}

export function* GET_LIST_ALL() {
  const success = yield call(topupListAll)
  if (success) {
    yield put({
      type: 'topup/SET_STATE',
      payload: {
        listAll: success,
      },
    })
  }
}

export function* loading() {
  yield put({
    type: 'topup/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'topup/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_BALANCE() {
  const { authorized } = yield select(state => state.user)
  if (authorized) {
    const success = yield call(topupGetBalance)
    if (success) {
      yield put({
        type: 'topup/SET_STATE',
        payload: {
          balance: success,
        },
      })
    }
  }
}

export function* GET_IS_TOP_UP() {
  const success = yield call(topupGetIsTopUp)
  if (success) {
    console.log(success, 'sagas')
    yield put({
      type: 'topup/SET_STATE',
      payload: success.data,
    })
  }
}

export function* GET_SUMMARY() {
  const success = yield call(topupGetSummary)
  if (success) {
    yield put({
      type: 'topup/SET_STATE',
      payload: {
        summary: success,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.GET_LIST_ALL, GET_LIST_ALL),
    takeEvery(actions.GET_BALANCE, GET_BALANCE),
    takeEvery(actions.GET_IS_TOP_UP, GET_IS_TOP_UP),
    takeEvery(actions.GET_SUMMARY, GET_SUMMARY),
    GET_BALANCE(),
  ])
}
