import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import { answerPaginate, answerStore, answerDelete } from 'services/answer'
import actions from './actions'

export function* GET_PAGINATE() {
  const { listTable } = yield select(state => state.answer)
  const { id } = yield select(state => state.excercise)
  if (!id) return
  yield loading()
  const success = yield call(answerPaginate, { ...listTable.paginate, excerciseId: id })
  if (success) {
    yield put({
      type: 'answer/SET_STATE',
      payload: {
        data: success.data.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)),
      },
    })
  }
  yield loaded()
}

export function* POST_STORE({ payload }) {
  yield loading()
  const success = yield call(answerStore, payload)
  if (success) {
    yield put({
      type: 'answer/GET_PAGINATE',
    })
  }
  yield loaded()
}

export function* DELETE_DATA({ payload }) {
  yield loading()
  yield call(answerDelete, payload)
  yield put({
    type: 'answer/GET_PAGINATE',
  })
}

export function* loading() {
  yield put({
    type: 'answer/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'answer/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.POST_STORE, POST_STORE),
    takeEvery(actions.DELETE_DATA, DELETE_DATA),
  ])
}
