import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  summary,
  lastRankPacket,
  scorePacketStatistic,
  getClosestTryout,
  postClosestTryout,
  getVideo,
} from 'services/dashboard'
import { bundleFuckingBundle } from 'services/bundle'
import actions from './actions'

export function* loading() {
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* firstFetch() {
  const { authorized } = yield select(state => state.user)
  if (authorized) {
    const { listFuckingBundle } = yield select(state => state.bundle)
    yield loading()
    const success = yield call(bundleFuckingBundle, listFuckingBundle.paginate)
    if (success) {
      yield put({
        type: 'bundle/SET_STATE',
        payload: {
          listFuckingBundle: {
            data: success.data,
            paginate: listFuckingBundle.paginate,
          },
        },
      })
      if (success.data.length) {
        yield put({
          type: 'dashboard/GET_SCORE_PACKET_STATISTIC',
          payload: {
            categoryId: success.data[0].category_id,
          },
        })
      }
    }
    yield loaded()
  }
}

export function* GET_SUMMARY() {
  yield loading()
  const success = yield call(summary)
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        summary: success,
      },
    })
    yield firstFetch()
    yield GET_VIDEO()
  }
  yield loaded()
}

export function* GET_LAST_RANK_PACKET() {
  yield loading()
  const { paginate } = yield select(state => state.dashboard.lastRankPacket)
  const success = yield call(lastRankPacket, paginate)
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        lastRankPacket: {
          ...success,
        },
      },
    })
  }
  yield loaded()
}

export function* GET_SCORE_PACKET_STATISTIC({ payload }) {
  yield put({
    type: 'dashboard/SET_STATE',
    payload,
  })
  yield loading()
  const { authorized } = yield select(state => state.user)
  if (authorized) {
    const success = yield call(scorePacketStatistic, payload)
    if (success) {
      yield put({
        type: 'dashboard/SET_STATE',
        payload: {
          scorePacketStatistic: success,
        },
      })
      yield put({
        type: 'bundle/GET_PACKET_ANSWER_USER_COMPLETE',
      })
    }
  }
  yield loaded()
}

export function* GET_CLOSEST_TRY_OUT() {
  yield loading()
  const success = yield call(getClosestTryout)
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        closest_tryout_date: success,
      },
    })
  }
  yield loaded()
}

export function* POST_CLOSEST_TRY_OUT() {
  yield loading()
  const data = yield select(state => state.dashboard)
  const success = yield call(postClosestTryout, data.closest_tryout_date)
  if (success) {
    yield put({
      type: 'dashboard/GET_CLOSEST_TRY_OUT',
    })
  }
  yield loaded()
}

export function* GET_VIDEO() {
  const { authorized } = yield select(state => state.user)
  if (authorized) {
    yield loading()
    const success = yield call(getVideo)
    if (success) {
      yield put({
        type: 'dashboard/SET_STATE',
        payload: {
          video: success.data,
        },
      })
    }
    yield loaded()
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SUMMARY, GET_SUMMARY),
    takeEvery(actions.GET_LAST_RANK_PACKET, GET_LAST_RANK_PACKET),
    takeEvery(actions.GET_SCORE_PACKET_STATISTIC, GET_SCORE_PACKET_STATISTIC),
    takeEvery(actions.GET_CLOSEST_TRY_OUT, GET_CLOSEST_TRY_OUT),
    takeEvery(actions.POST_CLOSEST_TRY_OUT, POST_CLOSEST_TRY_OUT),
    takeEvery(actions.GET_VIDEO, GET_VIDEO),
  ])
}
