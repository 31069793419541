import React from 'react'
import { Modal, Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { ReactComponent as ImgModal } from 'assets/modal.svg'

export const ImageModal = <ImgModal />

export const modalProps = {
  title: '',
  icon: false,
  className: 'text-center rounded-lg',
  width: 600,
  closable: true,
  footer: null,
  closeIcon: <CloseCircleOutlined twoToneColor="#9298A8" />,
  okButtonProps: { style: { display: 'none' } },
  cancelButtonProps: { style: { display: 'none' } },
  centered: true,
}

export function successSave(p) {
  return Modal.confirm({
    ...modalProps,
    content: (
      <div className="my-5">
        <strong>
          <h3>{p || 'Data Telah Tersimpan'}</h3>
        </strong>
        <ImgModal className="my-4" />
        <br />
        <Button type="primary" onClick={() => Modal.destroyAll()} className="mb-4" size="large">
          Oke Terima Kasih
        </Button>
      </div>
    ),
  })
}

export function successDelete() {
  return Modal.confirm({
    ...modalProps,
    content: (
      <>
        <strong>
          <h3>Data Telah Dihapus</h3>
        </strong>
        <ImgModal className="my-4" />
        <br />
        <Button type="primary" onClick={() => Modal.destroyAll()} className="mb-4" size="large">
          Oke Terima Kasih
        </Button>
      </>
    ),
  })
}
