const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  PASSWORD_EMAIL: 'user/PASSWORD_EMAIL',
  PROFILE: 'user/PROFILE',
  AVATAR_UPLOAD: 'user/AVATAR_UPLOAD',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
}

export default actions
