import apiClient from 'services/axios'
import response from 'services/response'

export async function summary() {
  let res = false
  try {
    res = await apiClient.get('tryout/dashboard-enduser/summary')
    return res.data
  } catch (error) {
    console.log(error)
    return false
  }
}

export async function lastRankPacket({ perPage, currentPage }) {
  try {
    const { data } = await apiClient.get(
      `tryout/dashboard-enduser/last-rank-packet-with-pagination/${perPage}/${currentPage}`,
    )
    return {
      ...response({ ...data }),
      packet: data.packet,
    }
  } catch (error) {
    return error
  }
}

export async function scorePacketStatistic(payload) {
  let res = false
  const { categoryId } = payload
  try {
    res = await apiClient.get(`tryout/dashboard-enduser/score-packet-statistic/${categoryId}`)
    return res.data
  } catch (error) {
    console.log(error)
    return false
  }
}

export async function getClosestTryout() {
  let res = false
  try {
    res = await apiClient.get(`tryout/closest-tryout`)
    return res.data
  } catch (error) {
    console.log(error)
    return false
  }
}

export async function postClosestTryout(payload) {
  let res = false
  try {
    res = await apiClient.post(`tryout/closest-tryout`, payload)
    return res.data
  } catch (error) {
    console.log(error)
    return false
  }
}

export async function getVideo() {
  let res = false
  try {
    res = await apiClient.get(`master/cms-video/list-all`)
    return res.data
  } catch (error) {
    console.log(error)
    return false
  }
}
