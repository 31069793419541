const actions = {
  SET_STATE: 'dashboard/SET_STATE',
  GET_SUMMARY: 'dashboard/GET_SUMMARY',
  GET_LAST_RANK_PACKET: 'dashboard/GET_LAST_RANK_PACKET',
  GET_SCORE_PACKET_STATISTIC: 'dashboard/GET_SCORE_PACKET_STATISTIC',
  GET_CLOSEST_TRY_OUT: 'dashboard/GET_CLOSEST_TRY_OUT',
  GET_VIDEO: 'dashboard/GET_VIDEO',
  POST_CLOSEST_TRY_OUT: 'dashboard/POST_CLOSEST_TRY_OUT',
}

export default actions
