import { all, put, takeEvery } from 'redux-saga/effects'
import actions from './actions'

export function* GET_DATA({ payload }) {
  yield put({
    type: 'packet/SET_STATE',
    payload: {
      visible: true,
      data: payload,
    },
  })
}

export function* loading() {
  yield put({
    type: 'packet/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'packet/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DATA, GET_DATA)])
}
