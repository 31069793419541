import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'created_at',
    },
  },
  listAll: [],
  id: '',
  data: [],
  balance: {
    point_balance_used: 0,
    point_balance_amount: 0,
  },
  forms: {
    item: [
      {
        name: 'amount',
        rules: [{ required: true }],
        className: 'col-8',
        children: {
          component: 'input',
          type: 'number',
          placeholder: 'masukkan jumlah points yang ingin kamu tukar',
        },
      },
    ],
    fields: [
      {
        name: ['id'],
      },
      {
        name: ['points_name'],
      },
    ],
  },
  loading: false,
}

export default function pointsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
