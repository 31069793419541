import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'category_name',
    },
  },
  listAll: [],
  id: '',
  data: '',
  forms: {
    item: [
      {
        name: 'id',
        hidden: true,
        rules: [{ required: false }],
        children: '',
      },
      {
        label: 'Kategori',
        name: 'category_name',
        rules: [{ required: true }],
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Ketik nama kategori',
          className: 'form-control',
        },
      },
      {
        label: 'Materi',
        name: 'materials',
        rules: [{ required: true }],
        help: 'Bisa lebih dari 1 materi',
        children: {
          component: 'select',
          mode: 'multiple',
          placeholder: 'Pilih Materi',
          className: 'form-control',
          width: '100%',
          data: 'materials',
          style: {
            overflow: 'auto',
          },
        },
      },
    ],
    fields: [
      {
        name: ['id'],
      },
      {
        name: ['category_name'],
      },
      {
        name: ['materials'],
      },
    ],
  },
  loading: false,
}

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
