import apiClient from 'services/axios'
import response from 'services/response'

export async function categoryPaginate(payload) {
  const { perPage, currentPage, sort, search } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(`master/category/${perPage}/${currentPage}/${sort}${params}`)
    console.log(res)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function categoryListAll() {
  let res = null
  try {
    res = await apiClient.get(`master/category/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function categoryCreate(payload) {
  let res = null
  try {
    res = apiClient.post(`master/category`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function categoryUpdate(id, data) {
  let res = null
  try {
    res = apiClient.post(`master/category/${id}`, data)
    return res.data
  } catch (error) {
    return false
  }
}

export async function categoryFindById(id) {
  let res = null
  try {
    res = apiClient.get(`master/category/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function categoryDelete(id) {
  let res = null
  try {
    res = apiClient.delete(`master/category/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}
