import actions from './actions'

const initialState = {
  loading: false,
  question: [],
  id: null,
  index: 0,
  correction: [],
  answer: [],
  timer: 0,
}

export default function excerciseReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
