import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  referralPaginate,
  referralPaginateTopUp,
  referralPaginateUser,
  referralSummary,
  referralPaginateHistoryDraw,
  referralSetting,
  referralListBank,
  referralBalance,
  referralBonusPercent,
  referralBonusAmount,
} from 'services/referral'
import actions from './actions'

export function* GET_PAGINATE() {
  const { listTable } = yield select(state => state.referral)
  yield loading()
  const success = yield call(referralPaginate, listTable.paginate)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        listTable: {
          data: success.data,
          paginate: {
            ...listTable.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_PAGINATE_USER() {
  const { listPaginateUser } = yield select(state => state.referral)
  yield loading()
  const success = yield call(referralPaginateUser, listPaginateUser.paginate)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        listPaginateUser: {
          data: success.data,
          paginate: {
            ...listPaginateUser.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_PAGINATE_TOP_UP() {
  const { listPaginateTopUp } = yield select(state => state.referral)
  yield loading()
  const success = yield call(referralPaginateTopUp, listPaginateTopUp.paginate)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        listPaginateTopUp: {
          data: success.data,
          paginate: {
            ...listPaginateTopUp.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_PAGINATE_HISTORY_DRAW() {
  const { listPaginateHistoryDraw } = yield select(state => state.referral)
  yield loading()
  const success = yield call(referralPaginateHistoryDraw, listPaginateHistoryDraw.paginate)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        listPaginateHistoryDraw: {
          data: success.data,
          paginate: {
            ...listPaginateHistoryDraw.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_BANK() {
  yield loading()
  const success = yield call(referralListBank)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        listBank: success,
      },
    })
  }
  yield loaded()
}

export function* GET_SETTING() {
  yield loading()
  const success = yield call(referralSetting)
  if (success) {
    console.log(success, 'sucess')
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        listAll: success,
      },
    })
  }
  yield loaded()
}

export function* GET_SUMMARY() {
  yield put({
    type: 'referral/GET_BONUS_PERCENT',
  })
  yield put({
    type: 'referral/GET_BONUS_AMOUNT',
  })
  yield loading()
  const success = yield call(referralSummary)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        summary: success,
      },
    })
  }
  yield loaded()
}

export function* GET_BALANCE() {
  yield loading()
  const success = yield call(referralBalance)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        balance: success,
      },
    })
  }
  yield loaded()
}

export function* loading() {
  yield put({
    type: 'referral/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'referral/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_BONUS_PERCENT() {
  yield loading()
  const success = yield call(referralBonusPercent)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        bonusPercent: success,
      },
    })
  }
  yield loaded()
}
export function* GET_BONUS_AMOUNT() {
  yield loading()
  const success = yield call(referralBonusAmount)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        bonusAmount: success,
      },
    })
  }
  yield loaded()
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.GET_PAGINATE_USER, GET_PAGINATE_USER),
    takeEvery(actions.GET_PAGINATE_TOP_UP, GET_PAGINATE_TOP_UP),
    takeEvery(actions.GET_PAGINATE_HISTORY_DRAW, GET_PAGINATE_HISTORY_DRAW),
    takeEvery(actions.GET_SUMMARY, GET_SUMMARY),
    takeEvery(actions.GET_SETTING, GET_SETTING),
    takeEvery(actions.GET_BANK, GET_BANK),
    takeEvery(actions.GET_BALANCE, GET_BALANCE),
    takeEvery(actions.GET_BONUS_PERCENT, GET_BONUS_PERCENT),
    takeEvery(actions.GET_BONUS_AMOUNT, GET_BONUS_AMOUNT),
  ])
}
