import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'username',
    },
  },
  listAll: [],
  id: '',
  data: [],
  loading: false,
}

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
