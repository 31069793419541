import actions from './actions'

const initialState = {
  loading: false,
  summary: {
    ebook_count: 0,
    packet_free_count: 0,
    packet_finished_count: 0,
    packet_paid_count: 0,
  },
  lastRankPacket: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 5,
      pageCount: 0,
      totalCount: 0,
    },
    packet: {},
  },
  scorePacketStatistic: [],
  closest_tryout_date: {
    closest_tryout_date: '',
    closest_tryout_title: '',
  },
  categoryId: null,
  video: [],
}

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
