import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 6,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: '-created_at',
    },
  },
  listAll: [],
  id: '',
  data: '',
  forms: {
    item: [
      {
        name: 'id',
        hidden: true,
        rules: [{ required: false }],
        children: {},
      },
      {
        label: 'Nama Kupon',
        name: 'coupon_name',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'input',
          placeholder: 'Ketik Kupon',
          className: 'form-control',
        },
      },
      {
        label: 'Gambar',
        name: 'coupon_image_url',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'upload',
          placeholder: 'Pilih Gambar',
          className: 'form-control',
        },
      },
      {
        label: 'Kode Kupon',
        name: 'coupon_code',
        rules: [{ required: true }],
        className: 'col-6',
        children: {
          component: 'input',
          placeholder: 'Ketik Kupon',
          className: 'form-control',
          help: 'Maksimal 5 Mb',
        },
      },
      {
        label: 'Nominal Diskon',
        name: 'coupon_nominal',
        rules: [{ required: true }],
        className: 'col-6',
        children: {
          component: 'input',
          placeholder: 'Ketik Nominal',
          className: 'form-control',
        },
      },
      {
        label: 'Maksimal Penggunaan',
        name: 'coupon_is_unlimited',
        rules: [{ required: true }],
        className: 'col-6',
        children: {
          component: 'radio',
          options: [
            { label: 'Tak Terhingga', value: true },
            { label: 'Bisa Digunakan', value: false },
          ],
          optionType: 'button',
          buttonStyle: 'solid',
        },
      },
      {
        name: 'coupon_used_when_is_limited',
        rules: [{ required: false }],
        className: 'col-6',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Berapa?',
          addonAfter: 'Kali',
        },
      },
      {
        label: 'Masa Aktif',
        rules: [{ required: false }],
        className: 'col-6',
        children: {
          component: 'radio',
          options: [
            { label: 'Tidak', value: false },
            { label: 'Ya', value: true },
          ],
          optionType: 'button',
          buttonStyle: 'solid',
        },
      },
      {
        label: 'Masa Aktif',
        name: ['coupon_valid_from', 'coupon_valid_to'],
        rules: [{ required: false }],
        className: 'col-6',
        children: {
          component: 'daterange',
        },
      },
    ],
    fields: [
      { name: ['id'] },
      { name: ['coupon_code'] },
      { name: ['coupon_name'] },
      { name: ['coupon_image_url'] },
      { name: ['coupon_is_unlimited'] },
      { name: ['coupon_used_when_is_limited'] },
      { name: ['coupon_valid_from'] },
      { name: ['coupon_valid_to'] },
    ],
  },
  loading: false,
}

export default function couponReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
