import apiClient from 'services/axios'
import response from 'services/response'

export async function voucherPaginate(payload) {
  const { perPage, currentPage, sort, search } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(`/finance/voucher-redeem/${perPage}/${currentPage}/${sort}${params}`)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function voucherListAll() {
  let res = null
  try {
    res = await apiClient.get(`master/voucher/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function voucherCreate(payload) {
  let res = null
  try {
    res = await apiClient.post(`master/voucher`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function voucherUpdate(id, data) {
  let res = null
  try {
    res = await apiClient.post(`master/voucher/${id}`, data)
    return res.data
  } catch (error) {
    return false
  }
}

export async function voucherFindById(id) {
  let res = null
  try {
    res = await apiClient.get(`master/voucher/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function voucherDelete(id) {
  let res = null
  try {
    res = await apiClient.delete(`master/voucher/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}
