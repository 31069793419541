import apiClient from 'services/axios'
import response from 'services/response'

export async function usersPaginate(payload) {
  const { perPage, currentPage, sort, search } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(`master/user/${perPage}/${currentPage}/${sort}${params}`)
    console.log(res)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function usersListAll() {
  let res = null
  try {
    res = await apiClient.get(`master/users/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function usersCreate(payload) {
  let res = null
  try {
    res = await apiClient.post(`master/users`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function usersUpdate({ id }) {
  let res = null
  try {
    res = await apiClient.get(`master/user/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function usersFindById(id) {
  let res = null
  try {
    res = await apiClient.get(`master/users/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function usersDelete(id) {
  let res = null
  try {
    res = await apiClient.delete(`master/users/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}
