import actions from './actions'

const initialState = {
  summary: {
    packet_answer_user_average: 0,
    packet_answer_user_average_last_to: 0,
    packet_answer_user_count: 0,
    packet_answer_user_count_pass: 0,
  },
  materialReport: {
    benar_salah_kosong: {
      benar: 0,
      salah: 0,
      kosong: 0,
    },
    menurut_pilihan: {
      countExcerciseList: [],
      poinList: [],
      scoreList: [],
    },
    irt: {},
  },
  subMaterialReport: {
    data: {
      data: [],
      material_scoring_type: '',
    },
  },
  category: [],
  loading: false,
}

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
